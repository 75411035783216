import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextFieldInput from '../components/Form/TextFieldInput';
import * as yup from 'yup';
import useYupForm from '../hooks/useYupForm';
import { FormProvider } from 'react-hook-form';
import { useAuth } from '../contexts/AuthContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';

const loginSchema = yup.object({
  username: yup
    .string()
    .label('Nome do usuário')
    .min(2, 'minimo 2 caracteres')
    .max(50)
    .required('Campo obrigatório'),
  password: yup.string().required('Campo obrigatório'),
});

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function Login() {
  const { login } = useAuth();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const loginMethods = useYupForm(loginSchema);

  async function onSubmit(data) {
    console.log('chegou no login.js');
    console.log(data);

    setIsLoading(true);
    try {
      await login(data);
      console.log('deu boa');
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      if (!error.isAxiosError) throw error;
      if (error.response.status === 401) {
        setMessage('Não autorizado !');
      } else if (error.response.status === 403) {
        setMessage('Sem direito de acesso !');
      } else if (error.response.status === 404) {
        setMessage('Usuário nao encontrado !');
      } else if (error.code.status === 'ERR_NETWORK') {
        setMessage('Erro de conexão !');
      } else if (error.code.status === 'ERR_CONNECTION_TIMED_OUT') {
        setMessage('Tempo expirado !');
      }
      setOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FormProvider {...loginMethods}>
      <Container
        maxWidth="xs"
        sx={{
          display: 'flex',
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <Paper sx={{ p: 5, borderRadius: 6 }}>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ mb: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Autenticação
            </Typography>
            <Box
              component="form"
              noValidate
              sx={{ mt: 3 }}
              onSubmit={loginMethods.handleSubmit(onSubmit)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldInput
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nome"
                    name="username"
                    autoComplete="name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldInput
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </Grid>
                <LoadingButton
                  loading={isLoading}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  color="secondary"
                  type="submit"
                >
                  Entrar
                </LoadingButton>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
    </FormProvider>
  );
}
