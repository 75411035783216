import React from 'react';
import TextFieldInput from '../Form/TextFieldInput';
import FileFieldInput from './FileFieldInput';
import OwnerFieldInput from './OwnerFieldInput';
import AnttFieldInput from './AnttFieldInput';
import * as yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import TextFieldInputPlate from '../Form/TextFieldInputPlate';
import CrlvExerciseInput from '../Form/CrlvExerciseInput';
import CrlvStateInput from '../Form/CrlvStateInput';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { Slide } from '@mui/material';

import statesData from '../Form/validityDatesOfTheCrlv';
import WorkspaceFormButtons from '../EditWorkspaceForm/WorkspaceFormButtons';

const truckComponentFields = {
  crlv: yup.array().of(yup.string()).min(1).required().label('CRLV'),
  owner: yup
    .object()
    .required()
    .transform((v) => (v === null ? undefined : v))
    .label('Proprietário'),
  antt: yup.object().required().label('Antt'),
  crlvExercise: yup
    .number()
    .required('Campo Obrigatório')
    .test('invalid', 'Exercício inválido', function (date) {
      return [2023, 2024].includes(date);
    }),
  crlvState: yup.string().required('Campo Obrigatório'),
};

export const truckTractorSchema = yup.object({
  licensePlate: yup.string().required().length(8).label('Placa'),
  ...truckComponentFields,
});

export const truckTrailerSchema = yup.object({
  ...truckComponentFields,
});

export default function TruckComponentInput({ name, hideLicense, sx }) {
  const [plateNumber, setPlateNumber] = useState('');
  const [lastPlateNumber, setLastPlateNumber] = useState('');
  const [exerciseValue, setExerciseValue] = useState(0);
  const [stateValue, setStateValue] = useState('');
  const [crlvValid, setCrlvValid] = useState(false);
  const [openStatusSuccess, setOpenStatusSuccess] = useState(false);
  const [openStatusError, setOpenStatusError] = useState(false);
  const [message, setMessage] = useState('');

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={props.direction} ref={ref} {...props} />;
  });

  //recebe o valor da placa do componente filho
  const updatePlate = (plate) => {
    setPlateNumber(plate);
  };

  //Busca o último número da placa
  useEffect(() => {
    setLastPlateNumber(plateNumber[plateNumber.length - 1]);
  }, [plateNumber]);

  //Validação da CRLV
  const crlvValidation = useCallback(async () => {
    let isValid;

    if (plateNumber) {
      if (!exerciseValue || !stateValue) return false;

      try {
        //verifica se há o exercício no json
        const exerciseJson = statesData.options.find(
          (option) => option.year === exerciseValue
        );
        if (!exerciseJson) {
          setMessage(`Exercício Inválido!`);
          setOpenStatusError(true);
          return false;
        }

        //verifica se há o estado no json
        const stateJson = exerciseJson.states.find(
          (state) => state.stateName === stateValue
        );
        if (!stateJson) {
          setMessage(`Estado não encontrado!`);
          setOpenStatusError(true);
          return false;
        }

        //propriedade que contém todas as datas de validade
        const dateForState = stateJson.dateValidations;

        //Procura a data de validade no json
        let date;
        for (const [key, value] of Object.entries(dateForState)) {
          if (value.includes(parseInt(lastPlateNumber))) {
            date = key;
            break;
          }
        }

        if (!date) {
          setMessage(`Data de validade não encontrada`);
          setOpenStatusError(true);
          return false;
        }

        //formatando as datas
        const newDateCrlv = new Date(date);
        const formatBR = newDateCrlv.toLocaleDateString('pt-BR');
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        isValid = currentDate <= newDateCrlv ? true : false;

        setCrlvValid(isValid);

        if (!isValid) {
          setMessage(`CRLV Expirada em: ${formatBR}`);
          setOpenStatusError(true);
          return;
        }
        setMessage('CRLV Válida');
        setOpenStatusSuccess(true);
      } catch (error) {
        if (error.response.status === 401) {
          setMessage('Não autorizado !');
        } else if (error.response.status === 403) {
          setMessage('Sem direito de acesso !');
        } else if (error.response.status === 404) {
          setMessage('Usuário nao encontrado !');
        } else if (error.code.status === 'ERR_NETWORK') {
          setMessage('Erro de conexão');
        } else if (error.response.status === 500) {
          setMessage('Erro no servidor !');
        }
        setOpenStatusError(true);
      }
    }
    return setCrlvValid(true);
  }, [exerciseValue, stateValue, lastPlateNumber, plateNumber]);

  //a função crlvValidation só é acionada quando há o número da placa
  useEffect(() => {
    crlvValidation();
  }, [crlvValidation]);

  return (
    <>
      {!hideLicense && (
        <TextFieldInputPlate
          mask="aaa-9*99"
          {...sx}
          label="Placa"
          name={`${name}.licensePlate`}
          defaultValue={plateNumber}
          handlePlateChange={updatePlate}
        />
      )}
      <FileFieldInput {...sx} label="CRLV " name={`${name}.crlv`} />
      <CrlvExerciseInput
        {...sx}
        label="Exercício"
        name={`${name}.crlvExercise`}
        handleExerciseValue={setExerciseValue}
      />
      <CrlvStateInput
        label="Estado da CRLV"
        name={`${name}.crlvState`}
        handleStateValue={setStateValue}
      />
      <OwnerFieldInput {...sx} label="Proprietário" name={`${name}.owner`} />
      <AnttFieldInput {...sx} label="ANTT" name={`${name}.antt`} />

      <WorkspaceFormButtons disabled={crlvValid === false} valid={crlvValid} />

      <Snackbar
        open={openStatusError}
        autoHideDuration={3000}
        onClose={() => setOpenStatusError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Transition}
        TransitionProps={{
          onExited: () => {
            setOpenStatusError(false);
          },
        }}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openStatusSuccess}
        autoHideDuration={3000}
        onClose={() => setOpenStatusSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Transition}
        TransitionProps={{
          onExited: () => {
            setOpenStatusSuccess(false);
          },
        }}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
